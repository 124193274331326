.App {
  width: 100%;
  margin-right: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  background-color: #336699;
}
